var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        _vm._l(_vm.ticket.receipt.members, function (member) {
          return _c(
            "v-col",
            {
              key: member.mbcode,
              staticClass: "member-responses",
              attrs: { cols: "12" },
            },
            [
              _vm.showMember(member)
                ? _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pl-0 pt-1 grow d-flex flex-column" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-truncate",
                                  attrs: { cols: "9" },
                                },
                                [
                                  _c(
                                    "v-card-subtitle",
                                    { staticClass: "font-weight-bold pb-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-truncate" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(member.mbcode) +
                                              " | " +
                                              _vm._s(member.name) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex align-center",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _vm.hasMemberResponded(member.mbcode)
                                    ? _c(
                                        "v-chip",
                                        {
                                          staticClass: "px-3",
                                          attrs: {
                                            color: "green",
                                            "text-color": "white",
                                            "x-small": "",
                                          },
                                        },
                                        [_vm._v(" Received ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.hasMemberResponded(member.mbcode)
                            ? _c("v-row", { attrs: { dense: "" } }, [
                                _vm.latestResponses[member.mbcode]
                                  ? _c(
                                      "div",
                                      [
                                        _c("MemberResponse", {
                                          attrs: {
                                            member:
                                              _vm.latestResponses[
                                                member.mbcode
                                              ],
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm.showResponses
                            ? _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-card-text",
                                        { staticClass: "py-0" },
                                        [
                                          _vm._v(
                                            " Member has not responded yet "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
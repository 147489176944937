<template>
  <v-container>
    <v-row>
      <v-col
        class="member-responses"
        v-for="member in ticket.receipt.members"
        :key="member.mbcode"
        cols="12"
      >
        <v-card v-if="showMember(member)" outlined>
          <v-container class="pl-0 pt-1 grow d-flex flex-column">
            <v-row dense>
              <v-col cols="9" class="text-truncate">
                <v-card-subtitle class="font-weight-bold pb-0">
                  <div class="text-truncate">
                    {{ member.mbcode }} | {{ member.name }}
                  </div>
                </v-card-subtitle>
              </v-col>
              <v-col cols="3" class="d-flex align-center">
                <v-chip
                  v-if="hasMemberResponded(member.mbcode)"
                  class="px-3"
                  color="green"
                  text-color="white"
                  x-small
                >
                  Received
                </v-chip>
              </v-col>
            </v-row>
            <v-row v-if="hasMemberResponded(member.mbcode)" dense>
              <div v-if="latestResponses[member.mbcode]">
                <MemberResponse
                  :member="latestResponses[member.mbcode]"
                ></MemberResponse>
              </div>
            </v-row>
            <v-row v-else-if="showResponses" dense>
              <v-col>
                <v-card-text class="py-0">
                  Member has not responded yet
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MemberMixin from "@/mixins/MemberMixin";
export default {
  components: {
    MemberResponse: () => import("./MemberResponse.vue"),
  },
  props: {
    ticket: {
      type: Object,
      default() {
        return {};
      },
    },
    showResponses: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [MemberMixin],
  data() {
    return {};
  },
  computed: {
    latestResponses() {
      let responses = {};
      for (let response of this.ticket.responses) {
        responses[response.member_code] = response;
      }
      return responses;
    },
  },
  methods: {
    hasMemberResponded(memberCode) {
      let responded = false;
      if (this.ticket.responses) {
        responded = this.ticket.responses.some(
          (response) => response.member_code == memberCode,
        );
      }
      return responded;
    },
  },
};
</script>

<style>
.member-responses {
  padding: 0px;
}
</style>
